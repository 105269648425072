import {MutationTree} from "vuex";
import {AttractionState, Filter} from "@/store/attractions/types";
import {filtersTemplate} from "@/store/attractions/index";
import {cloneDeep} from "lodash";

export const mutations: MutationTree<AttractionState> = {
    setFilterOptions(state, options) {
        state.filterOptions = options;
    },
    setFilterVisibility(state, event) {
        state.showFilter = event.value === 'filter';
    },
    setAttractions(state, data) {
        state.numItems = data.total;
        if (data.clear) {
            state.attractions = data.items;
            state.page = 1;
        } else {
            state.attractions = [...state.attractions, ...data.items];
            state.page += 1;
        }
        state.hasMore = state.attractions.length < state.numItems;
    },
    updateFilters(state, setting: Array<Filter>) {
        const settings = cloneDeep(state.filters);

        setting.forEach(s => {
            settings[s.name] = s.value;
        });

        state.filters = settings;
    },
    setFilterString(state) {
        const getters = [];

        if (state.filters.countryId > 0) {
            getters.push('filters[]=countryId|' + state.filters.countryId);
        }

        if (state.filters.regionId > 0) {
            getters.push('filters[]=regionId|' + state.filters.regionId);
        }

        if (state.filters.categories.length) {
            getters.push('filters[]=tags|' + state.filters.categories.join('-'));
        }

        if (state.filters.text.length > 0) {
            getters.push('filters[]=search|' + state.filters.text);
        }

        state.filterString = getters.join('&');
        state.filterUpdated = Date.now();
    },
    clearFilters(state) {
        state.filters = {...filtersTemplate};
        state.filterString = '';
    },
    setCurrent(state, attraction) {
        state.current = attraction;
    }
}
