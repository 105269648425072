import {AttractionState} from "@/store/attractions/types";
import {AccomState, LatLon} from "@/store/accoms/types";
import {ToursState} from "@/store/tours/types";
import {AlertData} from "@/store/user/types";

export interface Language {
    name: string,
    locale: string
}

export interface Country {
    name: string,
    id: number,
    regions: Array<Region>
}

export interface Image {
    alt: string,
    url: string,
    path?: string,
    thumb: string,
    description: null|string,
    ordering?: number,
    id?: number,
    srcset?: string,
    width?: number,
    height?: number,
}

export interface Region {
    id: number,
    name: string,
    countryId: number,
    zoom: number
}

export interface Page {
    title: string,
    url: string
}

export interface HomepageData {
    text: string,
    title: string,
    images: Array<Image>,
    slogans?: Array<string>
}

export interface WebpageData {
    text: string,
    title: string,
    images: Array<Image>,
}

export interface RootState {
    settings: {[index: string]:any},
    languages: Array<Language>
    currLanguage: string,
    translations: {[index: string]:any},
    hasAttractions: boolean,
    hasTours: boolean,
    hasBookings:boolean,
    homepage: null|HomepageData,
    about: null|WebpageData,
    loading: boolean,
    menu: Array<Page>,
    website: string,
    countries: Array<Country>,
    routeBack: string,
    userPosition: null|LatLon,
    error: null|AlertData,
    clearForms: boolean
}

export interface State {
    root: RootState,
    attractions: AttractionState,
    accoms: AccomState,
    tours: ToursState
}

export enum requestTypes {
    get,
    post,
    newPost,
    statefulGet
}

export interface requestResult {
    error: boolean,
    data?: Record<any, unknown>
}
