export const settings = {
  version: "0.0.2",
  logo: "assets/img/central_asia-header.png",
  menu_logo: "assets/img/caz_logo.jpg",
  logoAlt: "Visit central asia",
  api: "https://visitcentralasia.org/api/mob",
  website: "https://visitcentralasia.org",
  hasCountries: true,
  baseCountryId: 0,
  hasRegions: true,
  mapCenter: {
    lat: 0,
    lng: 0
  },
  languages: [
    {
      name:  "English",
      langcode: "en"
    }
  ],
}
