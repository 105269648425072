import {MutationTree} from "vuex";
import {ToursState} from "@/store/tours/types";
import {filtersTemplate} from "@/store/tours/index";

export const mutations: MutationTree<ToursState> = {
    setFilterOptions(state, options) {
        state.filterOptions = options;
    },
    setFilterVisibility(state, event) {
        state.showFilter = event.value === 'filter';
    },
    setTours(state, tourData) {
        state.numTours = tourData.total;
        if (tourData.clear) {
            state.tours = tourData.items;
            state.page = 1;
        } else {
            state.tours = [...state.tours, ...tourData.items];
            state.page += 1;
        }
        state.hasMore = state.tours.length < state.numTours;
    },
    setFilterString(state) {
        const getters = [];

        if (state.filters.countries.length) {
            const filterName = (state.filters.limitCountries)? 'onlyCountries' : 'countries';
            const filterValue = state.filters.countries.join('-');
            getters.push('filters[]=' + filterName + '|' + filterValue);
        }

        if (state.filters.regions.length) {
            getters.push('filters[]=regions|' + state.filters.regions.join('-'));
        }

        if (state.filters.categories.length) {
            getters.push('filters[]=tags|' + state.filters.categories.join('-'));
        }

        if (state.filters.tourLength) {
            getters.push('filters[]=numDays|' + state.filters.tourLength);
        }

        if (state.filters.text.length > 0) {
            getters.push('filters[]=search|' + state.filters.text);
        }

        state.filterString = getters.join('&');
    },
    clearFilters(state) {
        state.filters = {...filtersTemplate};
        state.filterString = '';
    },
    setTour(state, payload) {
        state.current = payload.tour;
    },
}
