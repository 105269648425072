import {createStore} from 'vuex'
import {accoms} from './accoms';
import {tours} from './tours';
import {root} from './root/root';
import {attractions} from './attractions';
import {State} from "@/store/types";
import VuexPersistence from "vuex-persist";
import {maps} from "@/store/maps/maps";
import {user} from "@/store/user/user"

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state: State) => ({
        root: {
            settings: state.root.settings,
            languages: state.root.languages,
            currLanguage: state.root.currLanguage,
            translations: state.root.translations,
            hasAttractions: state.root.hasAttractions,
            hasTours: state.root.hasTours,
            hasBookings: state.root.hasBookings,
            homepage: state.root.homepage,
            about: state.root.about,
            menu: state.root.menu,
            website: state.root.website,
            countries: state.root.countries,
        },
    }),
})

export const store = createStore<State>({
    plugins: [vuexLocal.plugin],
    modules: {
        root: root,
        accoms: accoms,
        tours: tours,
        attractions: attractions,
        maps: maps,
        user: user
    }
})
