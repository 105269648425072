import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36464ea4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "menu-logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            "content-id": "main-content",
            type: "overlay"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_img, { src: _ctx.logo }, null, 8, ["src"])
                  ]),
                  _createVNode(_component_ion_list, { id: "main-menu" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (p, i) => {
                        return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                          "auto-hide": false,
                          key: `menu${i}`
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, {
                              onClick: ($event: any) => (_ctx.goTo(i)),
                              "router-link": p.url,
                              "router-direction": "root",
                              class: _normalizeClass({ selected: _ctx.selectedIndex === i }),
                              color: _ctx.menuitemClass(p.name),
                              lines: "full"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick", "router-link", "class", "color"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item_divider, { color: "transparent" }),
                  _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                    default: _withCtx(() => [
                      (_ctx.userMenu && _ctx.userMenu.length)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.userMenu, (menu, uIdx) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: `um-${uIdx}`
                            }, [
                              (menu.url)
                                ? (_openBlock(), _createBlock(_component_ion_item, {
                                    key: 0,
                                    color: (_ctx.isSelected(menu.url))? 'primary' : 'light',
                                    "router-direction": "root",
                                    "router-link": menu.url,
                                    lines: "full",
                                    class: _normalizeClass({ selected: _ctx.isSelected(menu.url) })
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getTranslation(menu.title)), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["color", "router-link", "class"]))
                                : (_openBlock(), _createBlock(_component_ion_item, {
                                    key: 1,
                                    lines: "full",
                                    color: "light",
                                    onClick: ($event: any) => (_ctx.callMethod(menu.action))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_label, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getTranslation(menu.title)), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                            ], 64))
                          }), 128))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item_divider, { color: "transparent" }),
                      _createVNode(_component_ion_item, {
                        "router-link": "/about",
                        "router-direction": "root",
                        color: _ctx.menuitemClass('About'),
                        lines: "full"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.trans.mobile.about_us), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      _createVNode(_component_ion_item, {
                        color: "tertiary",
                        lines: "full"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openWebsite && _ctx.openWebsite(...args)))
                              }, _toDisplayString(_ctx.trans.mobile.visit_website), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_router_outlet, { id: "main-content" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}