import {ActionTree} from "vuex";
import {AccomState} from "@/store/accoms/types";
import {requestTypes, State} from "@/store/types";
import {isUndefined} from "lodash";
import {handleRequest} from "@/apiHelper";

export const actions: ActionTree<AccomState, State> = {
    load({ commit, state, rootGetters }) {
        commit('setLoading', true, { root: true });
        const uri = rootGetters.getBaseUri;
        let getString = '?';
        getString += 'limit=' + state.limit;

        if (state.filterString.length > 0) {
            getString += '&' + state.filterString;
        }

        handleRequest(requestTypes.get, `${uri}/accoms${getString}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    data.clear = true;
                    commit('setAccoms', data);
                }
            });
    },
    loadMore({commit, rootGetters, state}) {
        commit('setLoading', true, { root: true });
        const uri = rootGetters.getBaseUri;
        let getString = '?';
        getString += 'limit=' + state.limit;
        getString += '&page=' + (state.page + 1);

        if (state.filterString.length > 0) {
            getString += '&' + state.filterString;
        }

        handleRequest(requestTypes.get, `${uri}/accoms${getString}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    data.clear = false;
                    commit('setAccoms', data);
                }
            });
    },
    loadAccom({ commit, state, rootGetters }, groupId: string) {
        if (
            ! state.currAccom || isUndefined(state.currAccom.groupId)
            || state.currAccom.groupId !== groupId
        ) {
            commit('setLoading', true, { root: true });
            commit('setAccom', { accom: {} });
            const uri = rootGetters.getBaseUri;

            handleRequest(requestTypes.get, `${uri}/accom/${groupId}`)
                .then(response => {
                    if (!response.error) {
                        const data = response.data;
                        commit('setAccom', { accom: data.accom});
                    }
                });
        }
    },
    loadFilterOptions({commit, rootGetters}) {
        const uri = rootGetters.getBaseUri;

        handleRequest(requestTypes.get, `${uri}/accoms/filter-options`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    commit('setFilterOptions', data.filters);
                }
            });
    }
}
