import {Module} from "vuex";
import {requestTypes, State} from "@/store/types";
import {MapState} from "@/store/maps/types";
import {settings} from "@/settings";
import {Accommodation, LatLon, MapItemAccommodation, Marker} from "@/store/accoms/types";
import {MapItemAttraction} from "@/store/attractions/types";
import {handleRequest} from "@/apiHelper";

export const maps: Module<MapState, State> = {
    namespaced: true,
    state: {
        mapCenter: settings.mapCenter,
        markers: [],
        accomsUpdated: 0,
        attractionsUpdated: 0,
        userPosition: {},
        userPositionChecked: 0,
        positionError: false,
        nearestAccoms: [],
    },
    getters: {
        getMarkers(state): Array<Marker> {
            return state.markers;
        },
        getCenter(state): LatLon {
            return state.mapCenter;
        },
        getNearest(state): Array<Accommodation>|false {
            return state.nearestAccoms;
        },
        getPositionError(state): string|false {
            return state.positionError;
        },
    },
    mutations: {
        updateAccomMarkers(state, accoms: Array<MapItemAccommodation>) {
            const markers = [...state.markers].filter(m => m.isAttraction);

            accoms.forEach(ac => {
                const newMarker = <Marker>{
                    position: {
                        lat: Number(ac.lat),
                        lng: Number(ac.lon),
                    },
                    title: ac.name,
                    image: ac.image,
                    id: ac.groupId,
                    types: ac.types,
                    icon: ac.markerIcon,
                    isAttraction: false
                };
                markers.push(newMarker);
            });

            state.markers = markers;
            state.accomsUpdated = Date.now();
        },
        updateAttractionMarkers(state, items: Array<MapItemAttraction>) {
            const markers = [...state.markers].filter(m => !m.isAttraction);
            items.forEach(att => {
                const newMarker = <Marker>{
                    position: {
                        lat: Number(att.lat),
                        lng: Number(att.lon),
                    },
                    title: att.name,
                    image: att.image,
                    icon: att.markerIcon,
                    id: att.slug,
                    types: [{type: 'attraction'}],
                    isAttraction: true
                };
                markers.push(newMarker);
            });

            state.markers = markers;
            state.attractionsUpdated = Date.now();
        },
        setNearest(state, payload) {
            state.nearestAccoms = payload.accoms;
        },
    },
    actions: {
        loadMapLocations({commit, state, rootState, rootGetters}) {
            if (
                state.markers.length < 1
                || state.accomsUpdated < rootState.accoms.filterUpdated
                || Date.now() - state.accomsUpdated > 15 * 60 * 1000
            ) {
                commit('updateAccomMarkers', []);
                commit('setLoading', true, { root: true });
                const uri = rootGetters.getBaseUri;
                const filterString = rootState.accoms.filterString;
                const filterUri = (filterString.length > 0)?
                    '?' + filterString:
                    '';

                handleRequest(requestTypes.get, `${uri}/maps/accoms${filterUri}`)
                    .then(response => {
                        if (!response.error) {
                            const data = response.data;
                            if (data.accoms !== 'undefined') {
                                commit('updateAccomMarkers', data.accoms);
                            }
                        }
                    });
            }
        },
        loadAttractions({commit, state, rootState, rootGetters}) {
            if (
                state.markers.length < 1
                || state.attractionsUpdated < rootState.attractions.filterUpdated
                || Date.now() - state.attractionsUpdated > 180 * 60 * 1000
            ) {
                const uri = rootGetters.getBaseUri;
                const filterString = rootState.attractions.filterString;
                const filterUri = (filterString.length > 0)?
                    '?' + filterString:
                    '';

                handleRequest(requestTypes.get, `${uri}/maps/attractions${filterUri}`)
                    .then(response => {
                        if (!response.error) {
                            const data = response.data;
                            if (data.items !== 'undefined') {
                                commit('updateAttractionMarkers', data.items);
                            }
                        }
                    });
            }
        },
        loadNearest({ commit, state, rootGetters }) {
            const position = rootGetters.getUserPosition;
            if (position) {
                state.nearestAccoms = [];
                const uri = rootGetters.getBaseUri;
                const filterUri = (rootGetters.getFilterUri)
                    ? `?${rootGetters.getFilterUri}&lat=${position.coords.latitude}&lon=${position.coords.longitude}`
                    : `?lat=${position.coords.latitude}&lon=${position.coords.longitude}`;

                handleRequest(requestTypes.get, `${uri}/json/nearest${filterUri}`)
                    .then(response => {
                        if (!response.error) {
                            commit('setNearest', { accoms: response.data });
                        }
                    });
            } else {
                commit('setLoading', false, { root: true });
            }
        },
    }
}
