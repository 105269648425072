import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import ('../views/Home.vue')
  },
  {
    path: '/nearby',
    name: 'Nearby',
    component: () => import ('../views/Nearby.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import ('../views/About.vue')
  },
  {
    path: '/tours',
    name: 'Tours',
    component: () => import ('../views/ToursList.vue')
  },
  {
    path: '/booking/tour/:id',
    name: 'TourBookingsForm',
    component: () => import ('../views/TourBookingsPage.vue')
  },
  {
    path: '/attractions',
    name: 'Attractions',
    component: () => import ('../views/AttractionsList.vue')
  },
  {
    path: '/attraction/:slug',
    name: 'Attraction',
    component: () => import ('../views/AttractionPage.vue'),
    children: [
      {
        path: '',
        redirect: 'info',
      },
      {
        path: 'info',
        name: 'AttractionInfo',
        component: () => import ('../views/AttractionInfo.vue')
      },
      {
        path: 'gallery',
        name: 'AttractionGallery',
        component: () => import ('../views/AttractionPictures.vue')
      },
    ]
  },
  {
    path: '/accommodations',
    name: 'Accommodations',
    component: () => import ('../views/AccomsList.vue')
  },
  {
    path: '/accom-filters/:back?',
    name: 'AccomFilters',
    component: () => import ('../views/AccomFilterPage.vue')
  },
  {
    path: '/attractions-filters/:back?',
    name: 'AttractionsFilter',
    component: () => import ('../views/AttractionsFilterPage.vue')
  },
  {
    path: '/langs',
    name: 'Langs',
    component: () => import ('../views/LanguageSettings.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import ('../views/Map.vue')
  },
  {
    path: '/accom/:id',
    name: 'Accom',
    component: () => import ('../views/Accom.vue'),
    children: [
      {
        path: '',
        redirect: 'info',
      },
      {
        path: 'info',
        name: 'AccomInfo',
        component: () => import ('../views/AccomInfo.vue')
      },
      {
        path: 'details',
        name: 'AccomDetails',
        component: () => import ('../views/AccomDetails.vue')
      },
      {
        path: 'gallery',
        name: 'AccomGallery',
        component: () => import ('../views/AccomPictures.vue')
      },
      {
        path: 'contact',
        name: 'AccomContact',
        component: () => import ('../views/AccomContact.vue')
      },
    ]
  },
  {
    path: '/booking/accom/:id',
    name: 'AccomBookingsForm',
    component: () => import ('../views/AccomBookingsPage.vue')
  },
  {
    path: '/tour/:id',
    name: 'Tour',
    component: () => import ('../views/Tour.vue'),
    children: [
      {
        path: '',
        redirect: 'info',
      },
      {
        path: 'info',
        name: 'TourInfo',
        component: () => import ('../views/TourInfo.vue')
      },
      {
        path: 'details',
        name: 'TourDetails',
        component: () => import ('../views/TourProgram.vue')
      },
      {
        path: 'gallery',
        name: 'TourGallery',
        component: () => import ('../views/TourPictures.vue')
      },
      {
        path: 'contact',
        name: 'TourContact',
        component: () => import ('../views/TourContact.vue')
      },
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import ('../views/auth/LoginPage.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import ('../views/auth/RegistrationPage.vue'),
  },
  {
    path: '/forgot-password',
    name: 'Forgot',
    component: () => import ('../views/auth/passwords/PasswordForgotPage.vue'),
  },

  {
    path: '/favorites',
    component: () => import ('../views/FavoritesPage.vue'),
    children: [
      {
        path: '',
        redirect: 'tours',
      },
      {
        path: 'accoms',
        name: 'FavAccoms',
        component: () => import ('../views/FavAccoms.vue')
      },
      {
        path: 'tours',
        name: 'FavTours',
        component: () => import ('../views/FavTours.vue')
      },
      {
        path: 'attractions',
        name: 'FavAttractions',
        component: () => import ('../views/FavAttractions.vue')
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
