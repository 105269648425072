import {Module} from "vuex";
import {Favorite, FavoriteRequest, UserState} from "@/store/user/types";
import {requestTypes, State} from "@/store/types";
import {cloneDeep} from "lodash";
import {handleRequest} from "@/apiHelper";

export const user: Module<UserState, State> = {
    namespaced: true,
    state: {
        profile: {
            name: '',
            favorites: {
                accoms: [],
                attractions: [],
                tours: []
            }
        },
        defaultMenu: [
            {
                title: 'app.login',
                url: '/login',
                action: null
            }
        ],
        loggedMenu: [
            {
                title: 'app.my_favorites',
                url: '/favorites',
                action: null
            },
            {
                title: 'app.logout',
                url: null,
                action: 'logout'
            },
        ],
        loggedInDate: 0,
    },

    getters: {
        menu(state, getters) {
            if (getters.isLoggedIn)
                return state.loggedMenu;

            return state.defaultMenu;
        },
        isLoggedIn(state) {
            // return true;
            return (state.loggedInDate + 60 *60 * 1000) > Date.now();
        },
        getFavAccoms(state) {
            return (
                state.profile
                && state.profile.favorites.accoms
            )? state.profile.favorites.accoms: [];
        },
        getFavTours(state) {
            return (
                state.profile
                && state.profile.favorites.tours
            )? state.profile.favorites.tours: [];
        },
        getFavAttractions(state) {
            return (
                state.profile
                && state.profile.favorites.attractions
            )? state.profile.favorites.attractions: [];
        },
        isFavorite: (state, getters) => (payload: FavoriteRequest) => {
            if (!state.profile || ! getters.isLoggedIn)
                return false;

            switch(payload.type) {
                case 'attraction':
                    return (state.profile.favorites.attractions && state.profile.favorites.attractions.length > 0)
                        ? state.profile.favorites.attractions.filter((att: Favorite) => att.id === payload.id).length > 0
                        : false;

                case 'accom':
                    return (state.profile.favorites.accoms)
                        ? state.profile.favorites.accoms.filter((acc: Favorite) => acc.id === payload.id).length > 0
                        : false;

                case 'tour':
                    return (state.profile.favorites.tours)
                        ? state.profile.favorites.tours.filter((t: Favorite) => t.id === payload.id).length > 0
                        : false;
            }

            return false;
        },
    },

    mutations: {
        setUser(state, payload) {
            state.profile = payload;
            state.loggedInDate = Date.now();
        },
        clearProfile(state) {
            state.loggedInDate = 0;
            state.profile = null;
        },
        removeFavorite(state, payload: FavoriteRequest) {
            const profile = cloneDeep(state.profile);

            switch(payload.type) {
                case 'attraction':
                    profile.favorites.attractions = profile.favorites.attractions.filter((att:Favorite) => att.id !== payload.id);
                    break;

                case 'accommodation':
                case 'accom':
                    profile.favorites.accoms = profile.favorites.accoms.filter((acc:Favorite) => acc.id !== payload.id);
                    break;

                case 'tour':
                    profile.favorites.tours = profile.favorites.tours.filter((t:Favorite) => t.id !== payload.id);
                    break;
            }

            state.profile = profile;
        },
        addFavorite(state, payload) {
            const profile = cloneDeep(state.profile);

            switch(payload.type) {
                case 'attraction':
                    profile.favorites.attractions.push(payload.favorite);
                    break;

                case 'accommodation':
                case 'accom':
                    profile.favorites.accoms.push(payload.favorite);
                    break;

                case 'tour':
                    profile.favorites.tours.push(payload.favorite);
                    break;
            }

            state.profile = profile;
        }
    },

    actions: {
        signIn({commit, rootGetters}, payload) {
            commit('setLoading', true, { root: true });
            const uri = rootGetters.website;

            handleRequest(requestTypes.newPost, `${uri}/login`, payload, uri)
                .then(response => {
                    if (!response.error) {
                        const data = response.data;
                        commit('setUser', data.profile);
                    }
                });
        },
        register({commit, rootGetters}, payload) {
            commit('setLoading', true, { root: true });
            const uri = rootGetters.website;

            handleRequest(requestTypes.newPost, `${uri}/register`, payload, uri)
                .then(response => {
                    if (!response.error) {
                        const data = response.data;
                        commit('setUser', data.profile);
                    }
                });
        },
        requestReset({commit, rootGetters}, payload) {
            commit('setLoading', true, { root: true });
            const uri = rootGetters.website;

            handleRequest(requestTypes.newPost, `${uri}/password/email`, payload, uri)
                .then(response => {
                    if (!response.error) {
                        const data = response.data;
                        const alert = {
                            header: rootGetters.getTranslations.app.alert,
                            message: data.message
                        }
                        commit('setError', alert, { root: true });
                        commit('setClearForm', true, {root: true});
                    }
                });
        },
        removeFavorite({commit, getters, rootGetters}, payload: FavoriteRequest) {
            commit('setLoading', true, { root: true });

            if (! getters.isLoggedIn) {
                commit('setLoginError', false, { root: true });
                commit('setLoading', false, { root: true });
            } else {
                const uri = rootGetters.getBaseUri;

                handleRequest(requestTypes.statefulGet, `${uri}/user/favorites/remove/${payload.type}/${payload.id}`)
                    .then(response => {
                        if (!response.error) {
                            commit('removeFavorite', payload);
                        }
                    });
            }
        },
        addFavorite({commit, getters, rootGetters}, payload: FavoriteRequest) {
            commit('setLoading', true, { root: true });

            if (! getters.isLoggedIn) {
                commit('setLoginError', false, { root: true });
                commit('setLoading', false, { root: true });
            } else {
                const uri = rootGetters.getBaseUri;

                handleRequest(requestTypes.statefulGet, `${uri}/user/favorites/add/${payload.type}/${payload.id}`)
                    .then(response => {
                        if (!response.error) {
                            const data = response.data;
                            commit('addFavorite', {type: payload.type, favorite: data.favorite});
                        }
                    });
            }
        },
        logout({commit, rootGetters, getters}) {
            commit('setLoading', true, { root: true });
            const uri = rootGetters.website;

            if (! getters.isLoggedIn) {
                commit('clearProfile');
                commit('setLoading', false, { root: true });
            } else {

                handleRequest(requestTypes.post, `${uri}/logout`)
                    .then(response => {
                        if (!response.error) {
                            commit('clearProfile');
                        }
                    });
            }
        }
    }
}
