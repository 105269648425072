import {GetterTree} from "vuex";
import {State} from "@/store/types";
import {AttractionState, Attraction, AttractionFilters} from "@/store/attractions/types";
import {isUndefined} from "lodash";

export const getters: GetterTree<AttractionState, State> = {
    getAll(state: AttractionState): Array<Attraction> {
        return state.attractions;
    },
    current(state): Attraction|false {
        return state.current;
    },
    getFilterSettings(state): AttractionFilters {
        return state.filters;
    },
    filterOptions(state) {
        return state.filterOptions;
    },
    showFilter(state) {
        return state.showFilter;
    },
    hasFilters(state) {
        return state.filterString.length > 0;
    },
    getTotal(state) {
        return state.numItems;
    },
    hasMore(state) {
        return state.hasMore;
    },
    currentHasPhotos(state): boolean {
        if (state.current) {
            return (
                ! isUndefined(state.current.images)
                && state.current.images.length > 1
            );
        }

        return false;
    },
}
