import {ActionTree} from "vuex";
import {requestTypes, State} from "@/store/types";
import {ToursState} from "@/store/tours/types";
import {isUndefined} from "lodash";
import {handleRequest} from "@/apiHelper"

export const actions: ActionTree<ToursState, State> = {
    load({commit, state, rootGetters}) {
        commit('setLoading', true, { root: true });
        const uri = rootGetters.getBaseUri;
        let getString = '?';
        getString += 'limit=' + state.limit;

        if (state.filterString.length > 0) {
            getString += '&' + state.filterString;
        }

        handleRequest(requestTypes.get, `${uri}/tours${getString}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    data.clear = true;
                    commit('setTours', data);
                }
            });
    },
    loadMore({commit, rootGetters, state}) {
        commit('setLoading', true, { root: true });
        const uri = rootGetters.getBaseUri;
        let getString = '?';
        getString += 'limit=' + state.limit;
        getString += '&page=' + (state.page + 1);

        if (state.filterString.length > 0) {
            getString += '&' + state.filterString;
        }

        handleRequest(requestTypes.get, `${uri}/tours${getString}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    data.clear = false;
                    commit('setTours', data);
                }
            });
    },
    loadFilterOptions({commit, rootGetters}) {
        const uri = rootGetters.getBaseUri;

        handleRequest(requestTypes.get, `${uri}/tours/filter-options`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    commit('setFilterOptions', data);
                }
            });
    },
    loadTour({ commit, state, rootGetters }, slug: string) {
        if (
            ! state.current || isUndefined(state.current.slug)
            || state.current.slug !== slug
        ) {
            commit('setLoading', true, { root: true });
            commit('setTour', { tour: {} });
            const uri = rootGetters.getBaseUri;

            handleRequest(requestTypes.get, `${uri}/tours/${slug}`)
                .then(response => {
                    if (!response.error) {
                        const data = response.data;
                        commit('setTour', { tour: data.tour});
                    }
                });
        }
    },
}
