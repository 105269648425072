import {ActionTree} from "vuex";
import {requestTypes, State} from "@/store/types";
import {AttractionState} from "@/store/attractions/types";
import {handleRequest} from "@/apiHelper";

export const actions: ActionTree<AttractionState, State> = {
    load({commit, state, rootGetters}) {
        commit('setLoading', true, { root: true });
        const uri = rootGetters.getBaseUri;
        let getString = '?';
        getString += 'limit=' + state.limit;

        if (state.filterString.length > 0) {
            getString += '&' + state.filterString;
        }

        handleRequest(requestTypes.get, `${uri}/attractions${getString}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    data.clear = true;
                    commit('setAttractions', data);
                }
            });
    },
    loadMore({commit, rootGetters, state}) {
        commit('setLoading', true, { root: true });
        const uri = rootGetters.getBaseUri;
        let getString = '?';
        getString += 'limit=' + state.limit;
        getString += '&page=' + (state.page + 1);

        if (state.filterString.length > 0) {
            getString += '&' + state.filterString;
        }

        handleRequest(requestTypes.get, `${uri}/attractions${getString}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    data.clear = false;
                    commit('setAttractions', data);
                }
            });
    },
    loadCurrent({commit, rootGetters}, slug) {
        const uri = rootGetters.getBaseUri;

        handleRequest(requestTypes.get, `${uri}/attraction/${slug}`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    commit('setCurrent', data.item);
                }
            });
    },
    loadFilterOptions({commit, rootGetters}) {
        const uri = rootGetters.getBaseUri;

        handleRequest(requestTypes.get, `${uri}/attractions/filter-options`)
            .then(response => {
                if (!response.error) {
                    const data = response.data;
                    commit('setFilterOptions', data);
                }
            });
    },
}
