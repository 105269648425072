import {Module} from 'vuex'
import {settings} from "@/settings";
import {requestTypes, RootState, State} from "@/store/types";
import {AlertData} from "@/store/user/types";
import {handleRequest} from "@/apiHelper";

const buildMenu = (hasTours: boolean, hasAttractions: boolean, hasLanguages: boolean, lang: any) => {
    const menu = [
        {
            title: lang.mobile.home,
            url: '/home',
            name: 'Home'
        },
    ];
    if (hasTours) {
        menu.push({
            title: lang.app.tours,
            url: '/tours',
            name: 'Tours'
        });
    }
    if (hasAttractions) {
        menu.push({
            title: lang.attraction.attractions,
            url: '/attractions',
            name: 'Attractions'
        });
    }
    menu.push({
        title: lang.accom.accommodations,
        url: '/accommodations',
        name: 'Accommodations'
    });
    menu.push({
        title: lang.mobile.search_on_map,
        url: '/map',
        name: 'Map'
    });
    // menu.push({
    //     title: lang.mobile.search_nearby,
    //     url: '/nearby',
    //     name: 'Nearby'
    // });
    if (hasLanguages) {
        menu.push({
            title: lang.mobile.select_language,
            url: '/langs',
            name: 'Langs'
        });
    }

    return menu;
};

export const root: Module<RootState, State> = {
    state() {
        return {
            settings: settings,
            languages: [
                {
                    name:  "English",
                    locale: "en"
                }
            ],
            currLanguage: "en",
            translations: {},
            hasAttractions: false,
            hasTours: false,
            hasBookings: false,
            homepage: null,
            about: null,
            loading: false,
            menu: [],
            website: '',
            countries: [],
            routeBack: '',
            userPosition: null,
            error: null,
            clearForms: false
        }
    },

    getters: {
        getHomepage(state) {
            return state.homepage;
        },
        hasHomepage(state) {
            return !!state.homepage;
        },
        getAbout(state) {
            return state.about;
        },
        hasAboutPage(state) {
            return !!state.about;
        },
        hasBookings(state) {
            return state.hasBookings;
        },
        getMenu(state) {
            return state.menu;
        },
        getLanguages(state) {
            return state.languages;
        },
        getTranslations(state: RootState) {
            return state.translations;
        },
        getLoadingState(state) {
            return state.loading;
        },
        getBaseUri(state) {
            return `${state.settings.api}/${state.currLanguage}`;
        },
        website(state: RootState) {
            return state.website;
        },
        countries(state) {
            return state.countries;
        },
        getBackRoute(state) {
            return state.routeBack;
        },
        hasBackRoute(state) {
            return state.routeBack.length > 0;
        },
        getUserPosition(state) {
            return state.userPosition;
        },
        error(state) {
            return state.error;
        },
        shouldClearForm(state) {
            return state.clearForms;
        }
    },

    mutations: {
        init(state, payload) {
            // eslint-disable-next-line no-return-assign,@typescript-eslint/ban-ts-comment
            // @ts-ignore
            Object.keys(payload).forEach(index => state[index] = payload[index]);
        },
        clearWebpages(state) {
            state.homepage = null;
            state.about = null;
        },
        setLoading(state, load) {
            state.loading = (load);
        },
        setHomepage(state, payload) {
            state.homepage = payload;
        },
        setAboutPage(state, payload) {
            state.about = payload;
        },
        setCurrLang(state, lang) {
            state.currLanguage = lang;
        },
        setTranslations(state, translations) {
            state.translations = translations;
        },
        setCountries(state, payload) {
            state.countries = payload.countries;
        },
        setBackroute(state, backroute) {
            state.routeBack = backroute;
        },
        setUserPosition(state, position) {
            state.userPosition = position;
        },
        setError(state, error:AlertData) {
            state.error = error;
        },
        setLoginError(state) {
            state.error = {
                header: state.translations.app.alert,
                message: state.translations.app.login_to_see_favorites
            }
        },
        setGeneralError(state, msg = null) {
            let message = state.translations.app.general_error;
            if (msg) message += `<br> (${msg})`;
            state.error = {
                header: state.translations.app.alert,
                message
            }
        },
        setClearForm(state, shouldClear: boolean) {
            state.clearForms = shouldClear;
        }
    },

    actions: {
        initialiseStore({ dispatch, commit, state }) {
            return new Promise((resolve, reject) => {
                dispatch('init')
                    .then(() => {
                        commit('clearWebpages');
                        dispatch('accoms/loadFilterOptions');
                        if (state.hasTours)
                            dispatch('tours/loadFilterOptions');
                        if (state.hasAttractions)
                            dispatch('attractions/loadFilterOptions');
                        return resolve('init complete')
                    })
                    .catch(() => reject('init failed'));
            });
        },
        init({ commit, state }) {
            const uri = settings.api;

            return new Promise((resolve, reject) => {
                if (settings.version === state.settings.version && state.translations.mobile) {
                    return resolve('initialized');
                }

                handleRequest(requestTypes.get, `${uri}/init/${state.currLanguage}`)
                    .then(response => {
                        if (!response.error) {
                            const payload = {...response.data};
                            delete payload.filters;
                            payload.menu = buildMenu(
                                response.data.hasTours, response.data.hasAttractions, (response.data.languages.length > 1), response.data.translations,
                            );
                            commit('init', payload);
                            resolve('initialized');
                        } else {
                            reject('initialization failed');
                        }
                    });
            });
        },
        setLanguage({ commit, dispatch, state }, lang) {
            if (state.currLanguage !== lang) {
                commit('setCurrLang', lang);
                dispatch('loadTranslations', lang);
                dispatch('loadCountries', lang);
            }
        },
        loadTranslations({commit}, lang) {
            commit('setLoading', true);

            handleRequest(requestTypes.get, `${settings.api}/get-translations/${lang}`)
                .then(response => {
                    if (!response.error) {
                        commit('setTranslations', response.data);
                    }
                });
        },
        loadCountries({commit, state}, lang) {
            const uri = `${state.settings.api}/${lang}`;

            handleRequest(requestTypes.get, `${uri}/countries`)
                .then(response => {
                    if (!response.error) {
                        commit('setCountries', { countries: response.data.countries });
                    }
                });
        },
        getHomepage({ commit, getters }) {
            const uri = getters.getBaseUri;

            handleRequest(requestTypes.get, `${uri}/home`)
                .then(response => {
                    if (!response.error) {
                        commit('setHomepage', response.data.page);
                    }
                });
        },
        getAboutPage({ commit, getters }) {
            const uri = getters.getBaseUri;

            handleRequest(requestTypes.get, `${uri}/about`)
                .then(response => {
                    if (!response.error) {
                        commit('setAboutPage', response.data.page);
                    }
                });
        },
        doBooking({commit, getters}, payload) {
            commit('setLoading', true);
            const uri = getters.website;

            handleRequest(requestTypes.newPost, `${uri}/bookings`, payload, uri)
                .then(response => {
                    if (!response.error) {
                        const data = response.data;
                        const alert = {
                            header: getters.getTranslations.app.alert,
                            message: data.message
                        }
                        commit('setError', alert);
                        commit('setClearForm', true);
                    }
                });
        }
    }
};
