import {store} from './store/store';
import {requestTypes} from "@/store/types";

const getCookieValue = (name: string) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

const getToken = () => {
    return getCookieValue('XSRF-TOKEN').replace('%3D', '=');
};

function getStatefulHeaders(options: RequestInit) {
    const token = getToken();
    options.headers = {
        'Content-Type': 'application/json',
        'With-Credentials': 'true',
        'Accept': 'application/json',
        'X-XSRF-TOKEN': token
    };
    options.credentials = 'include';
}

export const handleRequest = function(type: requestTypes, uri: string, body = [], baseUri = '')
{
    const options:RequestInit = {
        method: 'GET'
    };

    if (type === requestTypes.newPost) {
        if (baseUri.length < 1) {
            store.commit('setGeneralError', 'No base uri set', { root: true });
            return Promise.resolve({error: true, data: {}});
        }

        return requestToken(baseUri)
            .then((response) => {
                if (response.error) {
                    return {error: true, data: {}};
                } else {
                    getStatefulHeaders(options)
                    options.method= 'POST';
                    options.body = JSON.stringify(body);

                    return makeRequest(uri, options);
                }
            });

    } else {
        if (type === requestTypes.post) {
            getStatefulHeaders(options);
            options.method= 'POST';
            options.body = JSON.stringify(body);
        }

        if (type === requestTypes.statefulGet) {
            getStatefulHeaders(options);
        }

        return makeRequest(uri, options);
    }
}

function requestToken(uri: string) {
    return fetch(`${uri}/sanctum/csrf-cookie`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
        }
    })
        .then(response => {
            if (!response.ok)
                throw new Error(response.status + ': ' + response.statusText);

            return {error: false};
        })
        .catch((err) => {
            store.commit('setGeneralError', err, { root: true });
            store.commit('setLoading', false, {root: true});
            return {error: true, data: false, message: err};
        });
}

function makeRequest(uri: string, options: RequestInit) {
    return fetch(uri, options)
        .then(response => {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return response.json()
                    .then(data => {
                        if (!response.ok) {
                            if (data && 'message' in data) {
                                const alert = {
                                    header: store.getters['getTranslations'].app.alert,
                                    message: data.message
                                }
                                store.commit('setError', alert, { root: true });
                                return {
                                    error: true,
                                    data: data
                                };
                            } else {
                                throw new Error(response.status + ': ' + response.statusText);
                            }
                        }

                        return {
                            error: false,
                            data: data
                        };
                    });
            } else {
                if (!response.ok) {
                    throw new Error(response.status + ': ' + response.statusText);
                }

                return {
                    error: false,
                    data: null
                };
            }
        })
        .catch(
            (err) => {
                store.commit('setGeneralError', err, { root: true });
                return {error: true, data: false, message: err};
            }
        )
        .finally(() => {
            store.commit('setLoading', false, {root: true});
        });
}
