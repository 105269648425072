import { Module } from "vuex";
import { State } from "@/store/types";
import {getters} from "@/store/tours/getters";
import {mutations} from "@/store/tours/mutations";
import {actions} from "@/store/tours/actions";
import {ToursState} from "@/store/tours/types";

export const filtersTemplate = {
    countries: [],
    regions: [],
    limitCountries: false,
    categories: [],
    tourLength: null,
    text: ""
}

const state: ToursState = {
    tours: [],
    filters: {
        ...filtersTemplate
    },
    filterString: '',
    current: false,
    filterOptions: false,
    showFilter: false,
    numTours: false,
    page: 0,
    limit: 8,
    hasMore: true,
};

export const tours: Module<ToursState, State> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
