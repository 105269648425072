import {MutationTree} from "vuex";
import {AccomState} from "@/store/accoms/types";
import {filtersTemplate} from "@/store/accoms";
import {Filter} from "@/store/attractions/types";
import {cloneDeep} from "lodash";

export const mutations: MutationTree<AccomState> = {
    setAccoms(state, payload) {
        state.numAccoms = payload.total;
        if (payload.clear) {
            state.accoms = payload.items;
            state.page = 1;
        } else {
            state.accoms = [...state.accoms, ...payload.items];
            state.page += 1;
        }
        state.hasMore = state.accoms.length < state.numAccoms;
        state.updated = Date.now();
    },
    setAccom(state, payload) {
        state.currAccom = payload.accom;
    },
    setFilters(state, payload) {
        state.filters = payload;
    },
    updateFilters(state, setting: Array<Filter>) {
        const settings = cloneDeep(state.filters);

        setting.forEach(s => {
            settings[s.name] = s.value;
        });

        state.filters = settings;
    },
    setFilterOptions(state, options) {
        state.filterOptions = options;
    },
    setFilterVisibility(state, event) {
        state.showFilter = event.value === 'filter';
    },
    clearFilters(state) {
        state.filters = {...filtersTemplate};
        state.filterString = '';
    },
    setFilterString(state) {
        const getters = [];

        if (state.filters.countryId > 0) {
            getters.push('filters[]=countryId|' + state.filters.countryId);
        }

        if (state.filters.regions.length) {
            getters.push('filters[]=regions|' + state.filters.regions.join('-'));
        }

        if (state.filters.terms.length) {
            state.filters.terms.forEach(t => {
                if (t.type === 'spoken') {
                    getters.push(`filters[]=${t.type}|${t.selects.join('-')}`);
                } else {
                    t.selects.forEach(s => {
                        getters.push(`filters[]=${t.type}|${s}`);
                    });
                }
            })
        }

        if (state.filters.services.length) {
            state.filters.services.forEach(s => {
                let value = s.id.toString();
                if (s.children && s.subselects && s.subselects.length > 0 && s.subselects.length < s.children.length) {
                    if (s.property) {
                        value += '@' + s.property + '-' + s.subselects.join('-');
                    }
                }
                getters.push('filters[]=service|' + value);
            })
        }

        if (state.filters.text.length > 0) {
            getters.push('filters[]=search|' + state.filters.text);
        }

        state.filterString = getters.join('&');
        state.filterUpdated = Date.now();
    },

}
