import { Module } from "vuex";
import {State} from "@/store/types";
import {getters} from "@/store/attractions/getters";
import {mutations} from "@/store/attractions/mutations";
import {actions} from "@/store/attractions/actions";
import {AttractionState} from "@/store/attractions/types";

export const filtersTemplate = {
    countryId: 0,
    regionId: 0,
    categories: [],
    text: ""
}

const state: AttractionState = {
    attractions: [],
    filters: {
        ...filtersTemplate
    },
    filterString: '',
    current: false,
    filterOptions: false,
    showFilter: false,
    filterUpdated: 0,
    numItems: false,
    page: 0,
    limit: 8,
    hasMore: true,
};

export const attractions: Module<AttractionState, State> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
