import {GetterTree} from "vuex";
import {AccomState, Accommodation} from "@/store/accoms/types";
import {State} from "@/store/types";
import {isUndefined} from "lodash";

export const getters: GetterTree<AccomState, State> = {
    getAll(state): Array<Accommodation> {
        return state.accoms;
    },
    getTotal(state) {
        return state.numAccoms;
    },
    hasMore(state) {
        return state.hasMore;
    },
    getCurrent(state): Accommodation|false {
        return state.currAccom;
    },
    currentHasPhotos(state): boolean {
        if (state.currAccom) {
            return (
                ! isUndefined(state.currAccom.images)
                && state.currAccom.images.length > 0
            );
        }

        return false;
    },
    getFilterSettings(state) {
        return state.filters;
    },
    filterOptions(state) {
        return state.filterOptions;
    },
    showFilter(state) {
        return state.showFilter;
    },
    hasFilters(state) {
        return state.filterString.length > 0;
    },
}
