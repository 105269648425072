import { Module } from "vuex";
import { AccomState } from "@/store/accoms/types";
import {State} from "@/store/types";
import {getters} from "@/store/accoms/getters";
import {mutations} from "@/store/accoms/mutations";
import {actions} from "@/store/accoms/actions";

export const filtersTemplate = {
    countryId: 0,
    regions: [],
    terms: [],
    services: [],
    text: ""
}

const state: AccomState = {
    accoms: [],
    filters: {
        ...filtersTemplate
    },
    filterString: '',
    filterOptions: false,
    showFilter: true,
    filterUpdated: 0,
    numAccoms: false,
    page: 0,
    limit: 8,
    hasMore: true,
    currAccom: false,
    updated: 0,
};

export const accoms: Module<AccomState, State> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
