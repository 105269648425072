
import {
    IonItemDivider,
    IonApp,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonImg,
    alertController,
    AlertOptions
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {mapActions, mapGetters} from "vuex";
import {AlertData} from "@/store/user/types";

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonContent,
        IonItem,
        IonLabel,
        IonList,
        IonMenu,
        IonMenuToggle,
        IonRouterOutlet,
        IonSplitPane,
        IonItemDivider,
        IonImg
    },
    data() {
        return {
            selectedIndex: 0,
            logo: "/assets/img/cata_logo.jpg"
        };
    },
    computed: {
        ...mapGetters({
            appPages: 'getMenu',
            trans: "getTranslations",
            website: "website",
            userMenu: "user/menu",
            error: "error"
        })
    },
    watch: {
        error(val) {
            if (val) {
                this.showError(val);
            }
        }
    },
    methods: {
        ...mapActions({
            userLogout: "user/logout"
        }),
        callMethod(m: string) {
            switch (m) {
                case 'logout':
                    this.userLogout();
                    break;
            }
        },
        goTo(i: number) {
            this.selectedIndex = i;
        },
        isSelected(uri:string) {
            return this.$route.path.includes(uri);
        },
        openWebsite() {
            window.open(this.website);
        },
        menuitemClass(routeName: string) {
            return (routeName === this.$route.name)? 'primary' : 'light';
        },
        getTranslation(keys: string) {
            const segments = keys.split('.');
            return this.trans[segments[0]][segments[1]];
        },
        showError: async(payload: AlertData) => {
            const data:AlertOptions = {
                header: payload.header,
                message: payload.message,
                buttons: ['OK'],
            }

            if (payload.subHeader) {
                data.subHeader = payload.subHeader;
            }

            const alert = await alertController.create(data);

            await alert.present();
        }
    }
});
