import {GetterTree} from "vuex";
import {State} from "@/store/types";
import {ToursState, Tour, TourFilters} from "@/store/tours/types";
import {isUndefined} from "lodash";

export const getters: GetterTree<ToursState, State> = {
    getAll(state: ToursState): Array<Tour> {
        return state.tours;
    },
    getCurrent(state): Tour|false {
        return state.current;
    },
    getFilterSettings(state): TourFilters {
        return state.filters;
    },
    filterOptions(state) {
        return state.filterOptions;
    },
    showFilter(state) {
        return state.showFilter;
    },
    hasFilters(state) {
        return state.filterString.length > 0;
    },
    getTotal(state) {
        return state.numTours;
    },
    hasMore(state) {
        return state.hasMore;
    },
    currentHasPhotos(state) {
        if (state.current) {
            return (
                ! isUndefined(state.current.images)
                && state.current.images.length > 0
                && state.current.images[0].alt !== 'placeholder'
            );
        }

        return false;
    }
}
